<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="user-salaries"
              :show-labels="true"
              :hide-custom-filters="false"
              :compact="false"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>

        <hr />
        <b-row v-if="isLoading">
          <b-col>
            <div class="text-center">
              <b-spinner variant="light" />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading">
          <b-col>
            <div
              v-for="(userData, index) in report"
              :key="`user-data-${index}`"
            >
              <div v-if="userData.salaryTable.data.length > 0">
                <b-table
                  :fields="salaryTableColumns"
                  :items="userData.salaryTable.data"
                  bg-variant="ligth"
                  responsive
                />
              </div>
              <b-card header-bg-variant="transparent">
                <b-card-text>
                  <b-row>
                    <b-col>
                      <div>Start date: {{ userData.salary.salarystart }}</div>
                      <div>
                        First of Year: {{ userData.salary.firstOfYear }}
                      </div>
                      <div>Current date: {{ userData.salary.currentDate }}</div>
                      <div>
                        Current month: {{ userData.salary.currentMonth }}
                      </div>
                      <div>
                        Months worked: {{ userData.salary.monthsWorked }}
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        Rollover VL hours:
                        {{ userData.salary.VL_prev_rollover }}
                      </div>
                      <div>
                        Accrued VL hours:
                        {{ userData.salary.VL_accrued }}
                      </div>
                      <div>
                        Used paid VL hours:
                        {{ userData.salary.VL_used }}
                      </div>
                      <div>
                        Used unpaid VL hours:
                        {{ userData.salary.VL_used_unpaid }}
                      </div>
                      <div>
                        Not used VL hours:
                        {{ userData.salary.VL_not_used }}
                      </div>
                      <div>
                        Available VL hours: {{ userData.salary.VL_available }}
                      </div>
                    </b-col>

                    <b-col>
                      <div>
                        Rollover PSL hours:
                        {{ userData.salary.PSL_prev_rollover }}
                      </div>
                      <div>
                        Accrued PSL hours:
                        {{ userData.salary.PSL_accrued }}
                      </div>
                      <div>
                        Used paid PSL hours:
                        {{ userData.salary.PSL_used }}
                      </div>
                      <div>
                        Used unpaid PSL hours:
                        {{ userData.salary.PSL_used_unpaid }}
                      </div>
                      <div>
                        Not used PSL hours:
                        {{ userData.salary.PSL_not_used }}
                      </div>
                      <div>
                        Available PSL hours: {{ userData.salary.PSL_available }}
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        Rollover PLA hours:
                        {{ userData.salary.PLA_prev_rollover }}
                      </div>
                      <div>
                        Accrued PLA hours:
                        {{ userData.salary.PLA_accrued }}
                      </div>
                      <div>
                        Used paid PLA hours: {{ userData.salary.PLA_used }}
                      </div>
                      <div>
                        Used unpaid PLA hours:
                        {{ userData.salary.PLA_used_unpaid }}
                      </div>
                      <div>
                        Not unpaid PLA hours:
                        {{ userData.salary.PLA_not_used }}
                      </div>
                      <div>
                        Available PLA hours: {{ userData.salary.PLA_available }}
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <hr />
        <div v-if="report.length > 0">
          <span class="font-weight-bold">Count:</span> {{ report.length }}
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

import FilteringPanel from '@/components/FilteringPanel'

import { mapState } from 'vuex'

export default {
  props: {
    mode: {
      type: String,
      default: ''
    }
  },
  components: {
    FilteringPanel
  },
  data: function () {
    return {
      isLoading: false,
      rawData: {},
      filterPanel: {
        selected: {},
        filters: []
      },
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: 'select',
            dataType: 'string',
            title: 'Full name',
            name: 'user_name',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Job role',
            name: 'rolename',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Category',
            name: 'umng_user_category',
            trackby: 'id',
            label: 'label',
            options: [
              { id: 'Exempt', label: 'Exempt' },
              { id: 'Non-exempt', label: 'Non-exempt' },
              { id: 'None', label: 'None' }
            ],
            selected: {},
            multiple: true
          },

          {
            type: 'select',
            dataType: 'string',
            title: 'Employment status',
            name: 'umng_emp_status',
            trackby: 'id',
            label: 'label',
            options: [
              { id: 'Full time', label: 'Full time' },
              { id: 'Part time', label: 'Part time' },
              { id: 'None', label: 'None' }
            ],
            selected: {},
            multiple: true
          },

          {
            type: 'select',
            dataType: 'string',
            title: 'Salary active',
            name: 'umng_user_status',
            trackby: 'id',
            label: 'label',
            options: [
              { id: 'Yes', label: 'Yes' },
              { id: 'No', label: 'No' }
            ],
            selected: {},
            multiple: true
          }
        ]
      },
      report: [],

      salaryTableColumns: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'Full name',
          label: 'Full name'
        },
        /*
        {
          key: "Last name",
          label: "Last name"
        },
        {
          key: "First name",
          label: "First name"
        },
        */
        {
          key: 'Job role',
          label: 'Job role'
        },
        {
          key: 'Revision',
          label: 'Revision'
        },
        {
          key: 'salaryratehour',
          label: 'Hourly'
        },
        {
          key: 'salaryrateyear',
          label: 'Salary'
        },
        {
          key: 'yearlyCombined',
          label: 'Yearly'
        },
        {
          key: 'Category',
          label: 'Category'
        },
        {
          key: 'Employment status',
          label: 'Employment status'
        },
        {
          key: 'salaryinsurance',
          label: 'Insurance'
        },
        {
          key: 'Salary active',
          label: 'Salary active'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted () {},
  methods: {
    onChange (e) {
      this.filterPanel.selected = e

      this.drawFullReport(this.rawData.salaries)
    },
    onCustomFilterSelected (e) {
      if (e.data) this.onSelectedExpression(e)
    },
    onSelectedExpression (e) {
      //if selected expression is empty then apply usual filters
      if (!e || !e.data) {
        //apply selected filters
        this.filterData(this.$refs.filteringPanel.selected)
        return
      }

      let rawData = this.rawData.salaries

      let expressions = JSON.parse(e.data)

      let filteredData = this.$helpers.applyExpressions2Dataset(
        expressions,
        rawData,
        []
      )

      //  this.$refs["incentives"].dataTable.dataSet = filteredData;
      // this.presetFilteredData = filteredData;

      //this.rawData.salaries = filteredData;

      this.drawFullReport(filteredData)
    },
    drawFullReport (payload) {
      if (!payload) return

      let data = payload

      this.report = []

      data.forEach(s => {
        let payload = {
          salary: s
        }
        this.drawUserReport(payload)
      })
    },
    async getData () {
      let self = this

      let response = {}

      self.isLoading = true

      response.salaries = await self.$api.get('timetracker/users/salaries')

      self.isLoading = false

      return response
    },

    drawUserReport (payload) {
      let userData = {
        salary: {},
        salaryTable: {
          data: []
        }
      }

      userData.salary = payload.salary

      userData.salary.firstOfYear = moment()
        .startOf('year')
        .format('YYYY-MM-DD')
      userData.salary.currentDate = moment().format('YYYY-MM-DD')
      userData.salary.currentMonth = moment().format('MM')

      let mm =
        moment(userData.salary.salarystart) > moment().startOf('year')
          ? moment(userData.salary.salarystart)
          : moment().startOf('year')
      let diff = Math.round(
        moment()
          .startOf('month')
          .diff(moment(mm), 'months', true)
      )
      userData.salary.monthsWorked = diff

      userData.salary.yearlyCombined = (+userData.salary.salaryrateyear > 0
        ? +userData.salary.salaryrateyear
        : +payload.salary.salaryratehour * 2080
      ).toFixed(2)

      userData.salaryTable.data.push(payload.salary)

      this.report.push(userData)
    },
    async loadDictionaries () {
      /*   let payload = {
        active: true
      };
*/
      let self = this

      const roles = async () => {
        if (this.filteringPanel.filters.find(f => f.name === 'rolename')) {
          this.$api.get('roles').then(response => {
            this.filteringPanel.filters.find(
              f => f.name === 'rolename'
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.rolename
              }))
            ]
          })
        }
      }

      const users = async () => {
        if (this.filteringPanel.filters.find(f => f.name === 'user_name')) {
          this.$api.get('dictionaries/users').then(response => {
            this.filteringPanel.filters.find(
              f => f.name === 'user_name'
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ]
          })
        }
      }
      /*
      this.$refs.filteringPanel.setValue("user_status", {
        id: "Yes",
        name: "Yes"
      });
      */
      Promise.all([users(), roles()]).then(() => {
        self.filteringPanel.loaded = true
      })
    },
    async onFilteringPanelLoad () {
      this.rawData = await this.getData()
      console.log('rawData', this.rawData)
      this.drawFullReport(this.rawData.salaries)

      if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) {
        let e = this.$refs.filteringPanel.selected['custom-filter']

        if (e && e.data) this.onSelectedExpression(e)
      } else {
        this.filterData(this.$refs.filteringPanel.selected)
      }
    },
    filterData (e) {
      this.filteringPanel.selected = e

      let selected = this.filteringPanel.selected

      if (!this.rawData.salaries) return

      let filteredData = this.rawData.salaries

      console.log('selected', selected)

      console.log('length1', filteredData.length, selected)

      filteredData = filteredData.filter(i =>
        selected.umng_user_status && selected.umng_user_status.length > 0
          ? selected.umng_user_status.find(
              s => s.label === (!i['Salary active'] ? 'No' : 'Yes')
            )
          : true
      )

      filteredData = filteredData.filter(i =>
        selected.umng_emp_status && selected.umng_emp_status.length > 0
          ? selected.umng_emp_status.find(
              s =>
                s.label ===
                (!i['Employment status'] ? 'None' : i['Employment status'])
            )
          : true
      )

      filteredData = filteredData.filter(i =>
        selected.umng_user_category && selected.umng_user_category.length > 0
          ? selected.umng_user_category.find(
              s => s.label === (!i['Category'] ? 'None' : i['Category'])
            )
          : true
      )

      filteredData = filteredData.filter(i =>
        selected.rolename && selected.rolename.length > 0
          ? selected.rolename.find(s => s.label === i['Job role'])
          : true
      )

      filteredData = filteredData.filter(i =>
        selected.user_name && selected.user_name.length > 0
          ? selected.user_name.find(
              //s => s.label === i["last_name"] + " " + i["first_name"]
              s => s.label === i['Full name']
            )
          : true
      )

      console.log('length2', filteredData.length, JSON.stringify(selected))

      this.drawFullReport(filteredData)
    }
  },
  watch: {}
}
</script>
